<template>
    <div class="my-20 my-md-50">
        <div class="text-center mb-10 mb-md-16">
            <strong class="font-size-16 font-size-md-18">공유하기</strong>
        </div>
        <v-row justify="center" class="row--small">
            <v-col cols="auto">
                <v-btn @click="facebook" fab color="#1877f2">
                    <i class="icon icon-facebook"></i>
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn @click="naver" fab color="#1fbc03">
                    <i class="icon icon-naver"></i>
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn @click="kakaotalk" fab color="#fed900">
                    <i class="icon icon-kakao"></i>
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn fab @click="copyLink" color="grey-a">
                    <v-icon color="white">mdi-link</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        kakaoData: { type: Object, default: () => ({}) },
    },
    methods: {
        facebook() {
            const { href } = window.location;
            console.log({ href });
            FB.ui({ display: "popup", method: "share", href }, function(response) {});
        },
        naver() {
            let url = "https://share.naver.com/web/shareView";
            url += "?url=";
            url += encodeURI(encodeURIComponent(window.location.href));
            url += "&title=";
            url += encodeURI(this.kakaoData.content.title);
            window.open(url, "share", "left=0,top=0,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=0,width=557,height=863");
        },
        kakaotalk() {
            Kakao.Link.sendDefault(this.kakaoData);
        },
        copyLink() {
            navigator.clipboard.writeText(window.location.href);
            alert("복사되었습니다.");
        },
    },
};
</script>

<style></style>

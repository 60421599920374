<template>
    <div v-if="board" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ board.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small grey-2--text">
                        <v-col cols="auto">
                            <span>
                                기간 : <span class="grey-7--text">{{ board.period }}</span>
                            </span>
                        </v-col>
                        <v-col cols="auto">
                            <span>
                                조회수 : <span class="grey-7--text">{{ board.viewCount }}</span>
                            </span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="content_box">
                    <div v-html="board.content"></div>
                    <share-buttons v-bind="{ kakaoData }" />
                </td>
            </tr>
        </table>

        <div class="mt-20 mt-md-30">
            <div v-show="false" class="v-btn--group justify-end">
                <v-btn large color="grey-7" class="w-md-100 mw-md-100px">수정</v-btn>
                <v-btn large outlined color="grey-d" class="w-md-100 mw-md-100px"><span class="grey-7--text">삭제</span></v-btn>
                <v-btn large outlined color="grey-d" class="w-md-100 mw-md-100px"><span class="grey-7--text">답글</span></v-btn>
            </div>
            <div class="v-btn--group justify-end">
                <v-btn @click="$router.go(-1)" large outlined color="grey-d" class="w-md-100 mw-md-100px"><span class="grey-7--text">{{ $t("common.list") }}</span></v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import ShareButtons from "@/components/dumb/share-buttons.vue";

export default {
    components: {
        ShareButtons,
    },
    props: {
        board: { type: Object, default: null },
        me: { type: Object, default: null },
    },
    computed: {
        isBookmark() {
            return this.me ? this.me.bookmarks.find((bookmark) => bookmark._board == this.board._id) : false;
        },
        isLike() {
            return this.me ? this.me.likes.find((like) => like._board == this.board._id) : false;
        },

        kakaoData() {
            let { subject: title, content: description, thumb: imageUrl } = this.board || {};

            description = description.replace(/(<([^>]+)>)/gi, "").replace(/\s|&nbsp;+/gi, " ");
            imageUrl = `${window.location.origin}${imageUrl}`;
            const link = {
                mobileWebUrl: window.location.href,
                webUrl: window.location.href,
            };

            return {
                objectType: "feed",
                content: {
                    title,
                    description,
                    imageUrl,
                    link,
                },
                buttons: [
                    {
                        title: "웹으로 보기",
                        link,
                    },
                ],
            };
        },
    },
};
</script>

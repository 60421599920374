var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-20 my-md-50"
  }, [_vm._m(0), _c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "color": "#1877f2"
    },
    on: {
      "click": _vm.facebook
    }
  }, [_c('i', {
    staticClass: "icon icon-facebook"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "color": "#1fbc03"
    },
    on: {
      "click": _vm.naver
    }
  }, [_c('i', {
    staticClass: "icon icon-naver"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "color": "#fed900"
    },
    on: {
      "click": _vm.kakaotalk
    }
  }, [_c('i', {
    staticClass: "icon icon-kakao"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "color": "grey-a"
    },
    on: {
      "click": _vm.copyLink
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v("mdi-link")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center mb-10 mb-md-16"
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-18"
  }, [_vm._v("공유하기")])]);

}]

export { render, staticRenderFns }
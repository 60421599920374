var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('div', {
    staticClass: "board-container"
  }, [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.board.subject))])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small grey-2--text"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(" 기간 : "), _c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v(_vm._s(_vm.board.period))])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(" 조회수 : "), _c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v(_vm._s(_vm.board.viewCount))])])])], 1)], 1)]), _c('tr', [_c('td', {
    staticClass: "content_box",
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.content)
    }
  }), _c('share-buttons', _vm._b({}, 'share-buttons', {
    kakaoData: _vm.kakaoData
  }, false))], 1)])]), _c('div', {
    staticClass: "mt-20 mt-md-30"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "v-btn--group justify-end"
  }, [_c('v-btn', {
    staticClass: "w-md-100 mw-md-100px",
    attrs: {
      "large": "",
      "color": "grey-7"
    }
  }, [_vm._v("수정")]), _c('v-btn', {
    staticClass: "w-md-100 mw-md-100px",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "grey-d"
    }
  }, [_c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v("삭제")])]), _c('v-btn', {
    staticClass: "w-md-100 mw-md-100px",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "grey-d"
    }
  }, [_c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v("답글")])])], 1), _c('div', {
    staticClass: "v-btn--group justify-end"
  }, [_c('v-btn', {
    staticClass: "w-md-100 mw-md-100px",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "grey-d"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v(_vm._s(_vm.$t("common.list")))])])], 1)])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }